<template>
  <div class="search-content">
    <div style="text-align: center">
      <img src="@/assets/images/knowledge.png" alt=""/>
    </div>
    <div class="search-input">
      <el-input
        ref="searchInput"
        size="medium"
        v-model="searchContent"
        placeholder="请输入内容"
        @focus="showHistory = true"
        @input="searchWord"
        @mouseleave.native="inputBlur"
      ></el-input>
      <el-button type="primary" @click="searchInput(searchContent)"
      >搜一搜
      </el-button
      >

      <el-button @click="intelliSearch" icon="robot" style="border-radius: 4px;margin-left: 24px"
      >进入智搜
      </el-button
      >
      <div class="history-dialog" v-if="searchContent && showSearchList && searchList.length >0"
           @mouseleave="showSearchList = false">
        <div style="max-height: 500px; overflow-y: auto">
          <div
            class="history-content"
            v-for="item in searchList"
            :key="item.id"
            @click="clickSearch(item.title)"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div
        class="history-dialog"
        v-if="!searchContent && showHistory && browseList.length > 0"
        @mouseleave="showHistory = false"
      >
        <div style="max-height: 500px; overflow-y: auto">
          <div
            class="history-content"
            v-for="item in browseList"
            :key="item.id"
            @click="clickSearch(item.fileName)"
            @mousemove="showClear(item)"
            @mouseleave="hideClear(item)"
          >
            <!-- <i
              class="el-icon-delete"
              @click="removeHistory(item.browseId, 'one')"
            ></i> -->
            <span :style="{color: item.show ? themeColor : ''}">{{ item.fileName }}</span>
            <span
              :style="{color:themeColor}"
              :underline="false"
              @click.stop="removeHistory(item.browseId, 'one')"
              v-if="item.show"
            >清除</span
            >
          </div>
        </div>
        <div class="btns">
          <!-- <button
            style="margin-right: 20px"
            class="dialog-btn"
            @click="removeHistory('', 'all')"
          >
            全部清除
          </button> -->
          <!-- <button
            v-if="browsePage.total > 10"
            class="dialog-btn"
            @click="moreData('history')"
          >
            更多
          </button> -->
          <span
            :style="{color:themeColor}"
            :underline="false"
            @click="removeHistory('', 'all')"
          >清除历史</span
          >
        </div>
      </div>
    </div>
    <!--    <div class="typeBtnBox">-->
    <!--      <el-button-group>-->
    <!--        <el-button :type="typeBtnActive == index?'primary':''" v-for="(item,index) in typeBtnList" :key="index" @click="chooseTypeBtn(item,index)">{{ item }}</el-button>-->
    <!--      </el-button-group>-->
    <!--    </div>-->
    <div class="main-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="最新发布" name="newPublish">
          <grid-layout
            class="knowledgeSearchGrid"
            ref="publishGridLayOut"
            :table-options="publishOptions"
            :table-data="publishData"
            :table-loading="publishLoading"
            :data-total="publishPage.total"
            :page="publishPage"
            @grid-row-detail-click="rowView"
            @page-current-change="publishOnLoad"
            @page-size-change="publishOnLoad"
          >
          </grid-layout>
        </el-tab-pane>
        <el-tab-pane label="我的收藏" name="collect">
          <grid-layout
            class="knowledgeSearchGrid"
            ref="gridLayOut"
            :table-options="tableOptions"
            :table-data="tableData"
            :table-loading="tableLoading"
            :data-total="page.total"
            :page="page"
            @grid-row-detail-click="rowView"
            @page-current-change="onLoad"
            @page-size-change="onLoad"
          >
          </grid-layout>
        </el-tab-pane>
        <!-- <el-tab-pane label="推荐" name="recommended"></el-tab-pane> -->
      </el-tabs>
      <div class="main-right">
        <el-tabs class="right-head" v-model="activeName1">
          <el-tab-pane label="置顶文件" name="hotsearch">
            <ul class="hot-content">
              <li
                v-for="item in hotSearchList"
                :key="item.id"
                @click="clickSearch(item.fileName)"
              >
                <img src="@/assets/images/fire.png" alt="" class="hot-icon"/>
                {{ item.fileName }}
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
        <!--        <div-->
        <!--          v-if="hotPage.total > 14"-->
        <!--          class="more-btn"-->
        <!--          @click="moreData('hot')"-->
        <!--        >-->
        <!--          <span :style="{color:themeColor}">显示更多</span>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {
  listCollect,
  listBrowse,
  removeBrowse,
  removeBrowseAll,
  search, listAllInfo, pageByDeptList,
} from "@/api/knowledgeClassifcation/knowledge";
import GridLayout from "@/views/business/knowledge/components/grid-layout";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      publishData: [],
      publishLoading: false,
      publishPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      typeBtnList: ['普通搜索', '智能搜索'],
      typeBtnActive: 0,
      activeName: "collect",
      activeName1: "hotsearch",
      showHistory: false,
      hotSearchList: [], //热门搜索
      browseList: [], //浏览历史
      browseListAll: [],
      hotPage: {
        currentPage: 1,
        total: 0,
      },
      browsePage: {
        currentPage: 1,
        total: 0,
      },
      searchContent: "",
      searchList: [],
      showSearchList: false
    };
  },

  components: {
    GridLayout,
  },
  watch: {
    themeColor(val) {
      this.themeColor = val;
    },
  },
  computed: {
    ...mapGetters(["themeColor"]),
    tableHeight() {
      return window.innerHeight - 395 + "px";
    },
    ids() {
      let ids = [];
      this.browseListAll.forEach((ele) => {
        ids.push(ele.browseId);
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        selection: false,
        menu: false,
        height: this.tableHeight,
        maxHeight: this.tableHeight,
        linklabel: "fileName",
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            align: "center",
            prop: "extension",
            width: 120,
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 180,
          },
          {
            label: "浏览时间",
            align: "center",
            prop: "collectTime",
            width: 180,
          },
        ],
      };
    },
    publishOptions() {
      return {
        selection: false,
        menu: false,
        height: this.tableHeight,
        maxHeight: this.tableHeight,
        linklabel: "fileName",
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            align: "center",
            prop: "extension",
            width: 120,
          },
          {
            label: "上传时间",
            align: "center",
            prop: "updateTime",
            width: 180,
          },
        ],
      };
    },
  },

  mounted() {
    this.onLoad(this.page, {});
    this.onLoadRight();
    this.onLoadBrowse();
  },

  methods: {
    chooseTypeBtn(item, index) {
      this.typeBtnActive = index;
    },
    inputBlur() {
      this.$refs.searchInput.blur();
    },
    rowView(row) {
      window.open(window.location.origin + `#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}&isNewWindow=true`);
      // window.open( `http://localhost:1888/#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}`);
    },
    showClear(item) {
      item.show = true;
    },
    hideClear(item) {
      item.show = false;
    },
    handleClick() {
      if (this.activeName === "collect") {
        this.onLoad(this.page, {})
      } else if (this.activeName === "newPublish") {
        this.publishOnLoad(this.publishPage, {})
      }
    },
    searchWord() {
      this.showSearchList = true;
      search(this.searchContent).then((res) => {
        if (res.status == 200) {
          this.searchList = res.data;
        }
      });
    },
    moreData(type) {
      if (type == "hot") {
        this.$router.push("/knowledge/knowledgeSetting/hotSearchManagement");
      } else {
        this.$router.push({
          path: "/knowledge/myKnowledgeBase",
          query: {
            type: "browsingHistory",
          },
        });
      }
    },
    removeHistory(id, type) {
      let idList = [];
      let ids = ''
      let browseName = '';
      this.browseListAll.forEach((e) => {
        if (e.browseId === id) {
          browseName = e.fileName
        }
      })
      this.browseListAll.forEach((e) => {
        if (browseName === e.fileName) {
          idList.push(e.browseId)
        }
      })
      ids = idList.join(",")
      console.log(ids, "ids")
      if (type == "all") {
        removeBrowseAll().then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.onLoadBrowse();
          }
        });
        return;
      }
      removeBrowse(ids).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.showHistory = false;
          this.browsePage.currentPage = 0;
          this.onLoadBrowse();
        }
      });
    },
    moreBrowse() {
      this.$router.push("/knowledge/searchList");
    },
    clickSearch(fileName) {
      this.searchContent = fileName;
      this.$router.push({
        path: "/knowledge/searchList",
        query: {
          search: this.searchContent,
        },
      });
    },
    searchInput(fileName) {
      this.searchContent = fileName;
      this.$router.push({
        path: "/knowledge/searchList",
        query: {
          search: this.searchContent,
        },
      });
    },
    intelliSearch() {
      // if (this.searchContent == '') {
      //   this.$message({
      //     type: "warning",
      //     message: "请输入搜索内容",
      //   });
      //   return;
      // }
      this.$router.push({
        path: "/knowledge/intelligent",
        query: {
          search: this.searchContent,
        },
      });
    },
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;
      listCollect(page.currentPage, page.pageSize).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    publishOnLoad(publishPage) {
      this.publishPage = publishPage;
      this.publishLoading = true;
      listAllInfo(publishPage.currentPage, publishPage.pageSize).then((res) => {
        const data = res.data.data;
        this.$refs.publishGridLayOut.page.total = data.total;
        this.publishData = data.records;
        this.publishLoading = false;
      });
    },
    onLoadRight() {
      pageByDeptList(this.hotPage.currentPage, 100).then((res) => {
        const data = res.data.data;
        this.hotSearchList = data.records;
        this.hotPage.total = data.total;
      });
    },
    deWeightFour(arr) {
      let obj = {};
      arr = arr.reduce(function (a, b) {
        obj[b.fileName] ? "" : (obj[b.fileName] = true && a.push(b));
        return a;
      }, []);
      return arr;
    },
    onLoadBrowse() {
      listBrowse(this.browsePage.currentPage, 9999).then((res) => {
        const data = res.data.data;
        this.browseListAll = data.records;
        data.records = this.deWeightFour(data.records);
        this.browseList = data.records.slice(0, 10);
        this.browseList.forEach((item) => {
          this.$set(item, "show", false);
        });
        this.browsePage.total = data.total;
      });
    },
  },
};
</script>

<style scoped lang='scss'>
::v-deep .robot {
  margin-right: 6px;
}
.search-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;

  .search-input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .el-input {
      width: 420px;
    }

    .el-button {
      cursor: pointer;
      height: 40px;
      border-radius: 0 4px 4px 0;
    }

    .history-dialog {
      width: 462px;
      position: absolute;
      top: 40px;
      left:calc(50% - 307px);
      padding: 12px;
      background: #fff;
      z-index: 999;
      border-left: 1px solid #DCDFE6;
      border-right: 1px solid #DCDFE6;
      border-bottom: 1px solid #DCDFE6;

      .history-content {
        line-height: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;

        &:hover {
          background: #F5F7FA;
        }

        span {
          font-size: 14px;
          color: #606266;
          padding-left: 12px;
        }
      }

      .btns {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;

        .dialog-btn {
          width: 90px;
          height: 30px;
          background: #1890ff;
          color: #fff;
          border: 0;
          font-size: 14px;
          border-radius: 3px;
        }
      }
    }
  }

  .typeBtnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .main-content {
    display: flex;
    padding-top: 10px;
    height: calc(100% - 205px);

    .el-tabs {
      width: 80%;
      border: 1px solid #ccc;

      ::v-deep .el-tabs__header {
        padding-left: 12px;
      }

      ::v-deep .el-tabs__content {
        padding: 0 !important;
      }
    }

    .main-right {
      //flex: 1;
      width: calc(20% - 15px);
      margin-left: 10px;
      border: 1px solid #ccc;
      position: relative;

      .right-head {
        height: 100%;
        //line-height: 40px;
        text-align: center;
        width: 100%;
        font-size: 14px;

        ::v-deep .el-tabs__content {
          padding: 0 !important;
          background-color: #fff !important;
          height: calc(100% - 55px);

          .el-tab-pane {
            height: 100%;
          }
        }
      }

      .hot-content {
        list-style: none;
        overflow-y: auto;
        padding: 0 12px;
        margin: 0;
        height: 100%;
        overflow: auto;

        li {
          line-height: 30px;
          font-size: 14px;
          cursor: pointer;
          text-align: left;
          color: #606266;
          overflow-x: hidden; /* 隐藏超出li的文本 */
          white-space: nowrap; /* 防止文本换行 */
          text-overflow: ellipsis; /* 超出部分显示为... */
        }

        .hot-icon {
          width: 30px;
          height: 30px;
          vertical-align: middle;
        }
      }

      .more-btn {
        position: absolute;
        top: 5px;
        right: 0;
        line-height: 30px;
        font-size: 12px;
        text-align: center;
        cursor: pointer;

        span {
          margin-right: 10px;
          text-decoration: underline;
        }
      }
    }
  }
}

::v-deep .el-input--medium .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
  border-radius: 4px 0 0 4px;
}

::v-deep .knowledgeSearchGrid .el-table {
  height: 520px !important;
  max-height: 520px !important;
}
</style>
